<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 wizard d-flex flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-forgot-on': this.state == 'forgot',
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div class="login-aside d-flex flex-column flex-row-auto">
        <div class="d-flex flex-column-auto flex-column">
          <a href="http://www.gruparge.com" class="text-center pt-10 pb-10">
            <img src="media/logos/logo-gruparge.jpg" class="max-h-200px" alt="" />
          </a>
          <h3 class="font-weight-bolder text-center font-size-h4 text-dark-50 line-height-xl">
            Yenilenebilir Enerji Santralleri <br />
            İzleme Sistemi
          </h3>
        </div>
        <div class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-center bgi-position-x-center mt-2" :style="{ backgroundImage: `url(${backgroundImage})` }"></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div class="login-content flex-row-fluid d-flex flex-column p-10">
        <div class="d-flex flex-row-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form class="form" novalidate="novalidate" id="kt_login_signin_form">
              <div class="pb-5 pb-lg-15">
                <h3 class="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">Giriş Yap</h3>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark">Email</label>
                <div id="example-input-group-1" label="" label-for="example-input-1">
                  <input class="form-control h-auto py-7 px-6 rounded-lg border-1" type="text" name="email" ref="email" v-model="form.email" autofocus placeholder="Email giriniz" />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5">Şifre</label>
                  <a class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5" id="kt_login_forgot" @click="showForm('forgot')">Şifremi Unuttum</a>
                </div>
                <div id="example-input-group-2" label="" label-for="example-input-2">
                  <input class="form-control h-auto py-7 px-6 rounded-lg border-1" type="password" name="password" ref="password" v-model="form.password" autocomplete="off" placeholder="Şifre giriniz" />
                </div>
              </div>
              <div class="pb-lg-0 pb-5 text-center">
                <button ref="kt_login_signin_submit" class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3" :disabled="buttonState">
                  {{ btnText }}
                </button>
              </div>
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form class="form" novalidate="novalidate" id="kt_login_forgot_form" ref="kt_login_forgot_form">
              <div class="pb-13 pt-lg-0 pt-5">
                <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Şifremi Unuttum</h3>
                <p class="text-muted font-weight-bold font-size-h4">Kayıtlı email adresinizi girip, şifrenizi sıfırlayın.</p>
              </div>
              <div class="form-group">
                <input class="form-control h-auto py-7 px-6 rounded-lg border-1" type="email" name="email" autocomplete="off" placeholder="Email giriniz" />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button type="button" id="kt_login_forgot_submit" class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4" :disabled="buttonState">Gönder</button>
                <button type="button" id="kt_login_forgot_cancel" class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3" @click="showForm('signin')">Vazgeç</button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
        <h3 class="font-weight-bolder text-center font-size-h4 text-dark-50 line-height-xl pt-10">Grup Arge {{ new Date().getFullYear() }} &copy;</h3>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss" scoped>
@import "@/assets/sass/pages/login/login-1.scss";

.flex-root {
  height: 100%;
}
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "login-1",
  data() {
    return {
      state: "signin",
      // Remove this dummy login info
      form: {
        email: "",
        password: "",
      },
      btnText: "Giriş Yap",
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser", "getErrors", "buttonState"]),
    backgroundImage() {
      return process.env.BASE_URL + "media/images/ges2.jpg";
    },
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");
    const forgot_form = KTUtil.getById("kt_login_forgot_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Kullanıcı adı zorunludur.",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "Şifre zorunludur.",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv2 = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Email zorunludur.",
            },
            emailAddress: {
              message: "Geçerli email adresi değildir.",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv.on("core.form.valid", () => {
      var username = this.form.email;
      var password = this.form.password;

      // Kullanıcı bilgileri ile giriş yapma
      this.$store
        .dispatch(LOGIN, { username, password })
        .then(() => {
          this.$router.push({ name: "dashboard" });
        })
        .catch((err) => {
          Swal.fire({
            title: "",
            text: "Tüm alanları doldurun!",
            icon: "error",
            heightAuto: false,
          });
        });
    });

    this.fv.on("core.form.invalid", () => {
      this.disableSubmitBool = true;
      Swal.fire({
        title: "",
        text: "Tüm alanları doldurun!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false,
      });
      this.disableSubmitBool = false;
    });
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(KTUtil.getById(form_name), "animate__animated animate__backInUp");
    },
    showLoginFail(message, type) {
      Swal.fire({
        text: message,
        icon: type,
        buttonsStyling: false,
        confirmButtonText: "Tamam",
        customClass: {
          confirmButton: "btn font-weight-bold btn-light-primary",
        },
      }).then(function () {
        KTUtil.scrollTop();
      });
    },
    toggleButton(button, toggle) {
      if (toggle) {
        button.classList.add("spinner", "spinner-light", "spinner-right");
        this.btnText = "Bekleyin";
      } else {
        button.classList.remove("spinner", "spinner-light", "spinner-right");
        this.btnText = "Giriş Yap";
      }
    },
  },
  watch: {
    getErrors(value) {
      if (value.status == false) {
        this.showLoginFail(value.message, "error");
      }
    },
    buttonState(value) {
      this.toggleButton(this.$refs["kt_login_signin_submit"], value);
    },
  },
};
</script>
